import { Component, ViewChild, inject, signal } from '@angular/core';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { combineLatest, map, switchMap, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SocialUser } from '@abacritt/angularx-social-login';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { ButtonComponent } from '@aprova-digital/design-system';

import { Types } from '../../../domain/types.domain';

import { AppHandler } from '../../app.handler';
import { InputComponent } from '../../components/input/input.component';
import { RecaptchaComponent } from '../../components/recaptcha/recaptcha.component';
import { ButtonComponent as ButtonComponentOLD } from '../../components/button/button.component';
import { IconComponent } from '../../components/icon/icon.component';
import { CheckboxComponent } from '../../components/checkbox/checkbox.component';
import { GoogleButtonModule } from '../../components/google-button/google-button.module';

import { AuthRepositoryInstruction } from '../../../builder/instructions/repositories/auth.repository.instructions';
import { UserRepositoryInstruction } from '../../../builder/instructions/repositories/user.repository.instructions';
import { LoginByIntegrationServiceInstruction } from '../../../builder/instructions/services/login-by-integration.service.instruction';
import { ResendAccountConfirmationServiceInstruction } from '../../../builder/instructions/services/resend-account-service.instruction';
import { GetConfigByCityIdOutput } from '../../../domain/auth/dtos/get-config-by-city-id.output';
import { PageTitleComponent } from '../../components/abstracts/page-title.component';

const REMEMBER_ME_COOKIE_NAME = 'username';
const REMEMBER_ME_COOKIE_EXPIRES = 7; // number of days

@Component({
  selector: 'app-login-by-form',
  standalone: true,
  imports: [
    AsyncPipe,
    NgOptimizedImage,
    ReactiveFormsModule,
    InputComponent,
    ButtonComponent,
    CheckboxComponent,
    RecaptchaComponent,
    IconComponent,
    ButtonComponentOLD,
    GoogleButtonModule,
  ],
  providers: [
    CookieService,
    new AuthRepositoryInstruction(),
    new UserRepositoryInstruction(),
    new LoginByIntegrationServiceInstruction(),
    new ResendAccountConfirmationServiceInstruction(),
  ],
  templateUrl: './login-by-form.component.html',
  styleUrl: './login-by-form.component.scss',
})
export class LoginByFormComponent extends PageTitleComponent('Login - Aprova') {
  @ViewChild('inputEmail', { static: false, read: InputComponent })
  public inputEmail: InputComponent | undefined;

  @ViewChild('inputPassword', { static: false })
  public inputPassword: InputComponent | undefined;

  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _appHandler = inject(AppHandler);
  private readonly _cookieService = inject(CookieService);
  private readonly _sanitizer = inject(DomSanitizer);

  private readonly _loginByIntegrationService = inject(
    Types.LoginByIntegrationService
  );
  private readonly _authRepository = inject(Types.AuthRepository);
  private readonly _resendAccountConfirmationService = inject(
    Types.ResendAccountConfirmationService
  );

  protected readonly data$ = combineLatest({
    loading: this._appHandler
      .loadingChange()
      .pipe(map((change) => change.loading)),
    city: this._route.data.pipe(
      map(({ city }) => ({
        cityIndex: city.index,
        cityIdentity: city.identidade,
        cityConnection: city.connection,
        cityActive: city.active,
      })),
      tap((data) => {
        if (!data.cityActive) {
          this._appHandler.setLoading(false);
          this._appHandler.hideBackButton(true);
          this._appHandler.setError(true, {
            iconName: 'exclamation-outline',
            iconSize: '48px',
            message: 'Indisponível',
            description: 'Consulte a organização para maiores esclarecimentos',
            options: {
              iconBackgroundColor: '#fff',
              messageClasses: 'font-bold',
              messageHorizontalAlign: 'center',
            },
          });
          return;
        }

        this.cityIndex = data.cityIndex;
        this.cityIdentity = data.cityIdentity;
        this.cityConnection = data.cityConnection;

        this._appHandler.setLoading(false);
        this.isRememberMeActive();
      })
    ),
    config: this._route.data.pipe(
      switchMap(({ city }) =>
        this._authRepository.getConfigByCityId(city.index)
      ),
      tap((config) => {
        if (config.background?.length) {
          this.backgroudImageUrl.set(config.background);
        }

        if (config.hideSignUp) {
          this.hideSignUp.set(true);
        }

        if (config.hideRecoverPassword) {
          this.hideRecoverPassword.set(true);
        }

        const credentialsMethod = config.methods.find((method) => {
          return method.strategy === 'CREDENTIALS';
        });

        if (credentialsMethod?.config) {
          if (credentialsMethod.config['identifierType']) {
            this.identifierType = credentialsMethod.config['identifierType'];
          }

          if (credentialsMethod.config['subtitle']) {
            this.subtitle.set(credentialsMethod.config['subtitle']);
          }
        }

        if (this.identifierType === 'email') {
          this.form.controls.email.setValidators([
            Validators.required,
            this.validateEmail,
          ]);
        } else if (this.identifierType === 'document') {
          this.form.controls.email.setValidators([
            Validators.required,
            this.validateDocumentFn,
          ]);
        } else {
          this.form.controls.email.setValidators([Validators.required]);
        }
      })
    ),
  });

  public routeState = this._router.getCurrentNavigation()?.extras?.state;

  public logginIn = signal(false);
  public backgroudImageUrl = signal<string | null>(null);
  public subtitle = signal('');
  public hideSignUp = signal(false);
  public hideRecoverPassword = signal(false);
  public identifierType: 'email' | 'document' | 'registration' = 'email';
  public identifierConfig = {
    email: {
      label: 'E-mail',
      placeholder: 'Digite seu e-mail',
      mask: '',
      errorMessages: {
        required: 'Ops! E-mail precisa ser preenchido.',
        email: 'Ops! Email inválido.',
        incorrect: 'E-mail ou senha incorretos.',
      },
    },
    document: {
      label: 'CPF/CNPJ',
      placeholder: 'CPF/CNPJ',
      mask: '000.000.000-00||00.000.000/0000-00',
      errorMessages: {
        required: 'Ops! CPF/CNPJ precisa ser preenchido.',
        cpfCnpj: 'Ops! CPF/CNPJ inválido.',
        incorrect: 'CPF/CNPJ ou senha incorretos.',
      },
    },
    registration: {
      label: 'Matrícula',
      placeholder: 'Digite sua matrícula',
      mask: '',
      errorMessages: {
        required: 'Ops! Matrícula precisa ser preenchida.',
        incorrect: 'Matrícula ou senha incorretos.',
      },
    },
  };
  public showPassword = signal(false);
  public rememberMe = signal(false);
  public urlCopied = signal(false);

  public cityLogo: string | undefined = undefined;
  public cityIndex: number = this._route.snapshot.data['city']['index'];
  public cityIdentity: string = this._route.snapshot.data['city']['identidade'];
  public cityConnection: string =
    this._route.snapshot.data['city']['connection'];

  public form = new FormGroup({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    password: new FormControl('', {
      nonNullable: true,
      validators: Validators.required,
    }),
    recaptchaReactive: new FormControl(''),
  });

  public isSocialLoginEnabled = signal(false);
  public isCertificateLoginEnabled = signal(false);
  public sessionTimeout = signal(false);
  public isAccountConfirmed = signal(false);

  constructor() {
    super();
    this.setSessionTimeout();
    this.setAccountConfirmed();
  }

  ngOnInit(): void {
    this._appHandler.setBackButtonHome(true);
    this._appHandler.setLoading(true);
  }

  isRememberMeActive(): void {
    const username = this._cookieService.get(REMEMBER_ME_COOKIE_NAME);
    if (username) {
      this.form.controls.email.setValue(username);
      this.rememberMe.set(true);
      this.inputPassword?.inputRef?.nativeElement.focus();
    }
  }

  redirectToCreateAccount(): void {
    this._router.navigate([`create-account`], {
      relativeTo: this._route,
    });
  }

  redirectToRecoverPassword(): void {
    this._router.navigate(['recover-password'], {
      relativeTo: this._route,
    });
  }

  toggleInputPassword(): void {
    this.showPassword.update((val) => !val);
  }

  handleRemembermeChange(): void {
    this.rememberMe.update((val) => !val);
  }

  onFormInput(): void {
    if (this.form.invalid && this.form.dirty && this.form.touched) {
      this.form.controls.email.setErrors(null);
      this.form.controls.password.setErrors(null);
      this.form.markAsPristine();
      this.form.markAllAsTouched();
    }
  }

  onSubmit(): void {
    if (!this.form.controls.email.value) {
      this.form.controls.email.setErrors({ required: true });
      this.form.controls.email.markAsDirty();
      this.form.controls.email.markAsTouched();
    }

    if (!this.form.controls.password.value) {
      this.form.controls.password.setErrors({ required: true });
      this.form.controls.password.markAsDirty();
      this.form.controls.password.markAsTouched();
    }

    this.form.updateValueAndValidity();

    if (this.form.invalid) {
      return;
    }

    this._appHandler.setError(false);
    this.logginIn.set(true);

    this._loginByIntegrationService
      .handle({
        strategy: 'CREDENTIALS',
        connection: this.cityConnection,
        cityId: this.cityIndex,
        email: this.form.controls.email.value,
        password: this.form.controls.password.value,
        recaptcha: this.form.value.recaptchaReactive,
      })
      .then((response: any) => {
        if (this.rememberMe()) {
          this._cookieService.set(
            REMEMBER_ME_COOKIE_NAME,
            this.form.controls.email.value,
            REMEMBER_ME_COOKIE_EXPIRES
          );
        } else {
          this._cookieService.delete(REMEMBER_ME_COOKIE_NAME);
        }

        if (response.requireChangePassword) {
          this._router.navigate(['change-password'], {
            relativeTo: this._route,
            queryParams: {
              email: response.email,
              token: response.token,
              type: response.type,
            },
          });
          return;
        }

        if (response.requireCompleteRegistration) {
          this._router.navigate(
            [`/${this.cityIdentity}/complete-registration`],
            {
              state: {
                userData: response.userData,
                token: response.tempToken,
                redirectUri: `${response.redirectUri}?code=${response.code}`,
              },
            }
          );
          return;
        }

        if (!response.requireTwoFactorAuth) {
          this.redirectTo(response);
          return;
        }

        this._router.navigate(['two-factor-authentication'], {
          relativeTo: this._route,
          state: {
            verificationToken: response.code,
            twoFactorAuthenticationName: response.twoFactorAuthName,
            ...(response.phoneNumber && {
              phoneNumber: response.phoneNumber,
            }),
          },
        });
      })
      .catch((errorResponse: any) => {
        this.handleLoginError(errorResponse);
      });
  }

  handleLoginError(errorResponse?: any): void {
    this.logginIn.set(false);

    if (errorResponse.error.code === 'incorretEmailPassword') {
      this.form.controls.email.setErrors({ incorrect: true });
      this.form.controls.password.setErrors({ incorrect: true });
      this.form.controls.email.markAsDirty();
      this.form.controls.password.markAsDirty();
      this.form.controls.recaptchaReactive.reset();
      this.form.controls.recaptchaReactive.markAsDirty();
      this.form.updateValueAndValidity();
      return;
    }

    if (errorResponse.error.code === 'cityNotActive') {
      this._appHandler.hideBackButton(true);
      this._appHandler.setError(true, {
        iconName: 'exclamation-outline',
        iconSize: '48px',
        message: 'Indisponível',
        description: 'Consulte a organização para maiores esclarecimentos',
        options: {
          iconBackgroundColor: '#fff',
          messageClasses: 'font-bold',
          messageHorizontalAlign: 'center',
        },
      });
      return;
    }

    if (errorResponse.error.code === 'userBlocked') {
      this._appHandler.setError(true, {
        message: 'Sua conta está bloqueada.',
        description:
          'Contate-nos através do chat, localizado no ícone no canto inferior direito, para prosseguir.',
      });
      this._appHandler.setBackButtonHome(false);
      return;
    }

    if (errorResponse.error.code === 'emailNotVerified') {
      this._appHandler.setError(true, {
        message: 'A verificação da conta está pendente.',
        description:
          'Ação necessária para acessar o sistema. Clique no botão abaixo.',
        actionText: 'Reenviar e-mail de confirmação de conta',
        action: () => {
          this.resendAccountConfirmation(errorResponse.error.params?.userId);
        },
      });

      return;
    }

    if (
      errorResponse.error.code === 'missingUserSectors' ||
      errorResponse.error.code === 'missingUserAuthorizationGroups'
    ) {
      this._appHandler.setError(true, {
        message: 'Ação necessária para o seu usuário acessar o sistema.',
        description:
          'Seu usuário ainda não possui: <strong>Permissão</strong> <br/><br/> Entre em contato com o Administrador do sistema da organização para solicitar essas liberações.',
        options: {
          useHtmlInMessage: true,
        },
      });
      return;
    }

    if (errorResponse?.error?.code === 'inviteNotAccepted') {
      this._appHandler.setError(true, {
        message: 'Seu cadastro não foi completado.',
        description:
          'Clique no botão abaixo para completar seu cadastro e retomar o acesso ao sistema.',
        actionText: 'Completar Cadastro',
        action: () => {
          window.location.href = errorResponse.error.params?.signUpUrl;
        },
      });

      return;
    }

    this._appHandler.setError(true, {
      message: 'A operação não pôde ser concluída.',
      description:
        'Por favor, revise seus dados antes de fazer uma nova tentativa. Se o problema persistir, entre em contato com nossa equipe de suporte.',
      actionText: 'Tentar novamente',
      action: () => {
        this._appHandler.clearError();
      },
    });
  }

  loginGovBr(config: GetConfigByCityIdOutput) {
    const govBrAuthMethod = config.methods.find(
      (method) => method.strategy === 'GOV_BR'
    );
    location.href = govBrAuthMethod?.config['url'];
  }

  private setSessionTimeout(): void {
    const sessionTimeout =
      this._route.snapshot?.queryParams?.['sessionTimeout'];
    this.sessionTimeout.set(parseInt(sessionTimeout) === 1);
  }

  private setAccountConfirmed(): void {
    const queryParams = this._route.snapshot.queryParams;
    if (queryParams && queryParams['accountConfirmed']) {
      this.isAccountConfirmed.set(true);
    }
  }

  async loginICP() {
    this._appHandler.setLoading(true);
    this._router.navigate(['icp'], {
      relativeTo: this._route,
    });
  }

  private validateEmail = (control: AbstractControl) => {
    if (!control.value) return null;

    const regex = /\S+@\S+\.\S+/;
    return regex.test(control.value) ? null : { email: true };
  };

  private validateDocumentFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const key: 'cpf' | 'cnpj' =
      this.form.controls.email.value.length <= 11 ? 'cpf' : 'cnpj';
    const instance: typeof cpf | typeof cnpj = key === 'cpf' ? cpf : cnpj;
    return instance.isValid(control.value) ? null : { cpfCnpj: true };
  };

  private redirectTo(response: any) {
    const { redirectTo, ...othersParams }: any =
      this._route.snapshot.queryParams;

    if (redirectTo) {
      let urlToRedirect = response.redirectUri;

      if (this.isUrlValid(redirectTo)) {
        const queryParams = {
          code: response.code,
          ...othersParams,
        };
        const params = new URLSearchParams(queryParams);
        urlToRedirect = `${redirectTo}?${params.toString()}`;
      } else {
        urlToRedirect = `${urlToRedirect}?code=${response.code}&redirectTo=${redirectTo}`;
      }

      window.location.href = urlToRedirect;
    } else {
      if (response.requireNewUser) {
        this._router.navigate([`/${this.cityIdentity}/create-account`], {
          queryParams: {
            skipAccountConfirmation: !response.options?.emailVerification,
          },
          state: {
            initialAccountData: response.userData,
            blockedFields: response.options?.blockFields,
          },
        });

        return;
      }

      const queryParams = response.code ? `?code=${response.code}` : '';
      window.location.href = `${response.redirectUri}${queryParams}`;
    }
  }

  private isUrlValid(url: string): boolean {
    const httpRegex =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    return httpRegex.test(url);
  }

  private resendAccountConfirmation(userId: string): void {
    this._appHandler.setError(true, {
      message: 'Enviando e-mail de confirmação de conta...',
      description:
        'Este processo pode levar alguns segundos. Por favor, aguarde.',
    });

    this._resendAccountConfirmationService
      .handle({
        cityId: this.cityIndex,
        userId,
      })
      .then(() => {
        this._appHandler.setError(true, {
          message: 'E-mail enviado com sucesso!',
          description:
            'Verifique sua caixa de entrada e siga as instruções para confirmar sua conta.',
        });
      })
      .catch((errorResponse: any) => {
        if (errorResponse.error.code === 'recentVerificationRequest') {
          this._appHandler.setError(true, {
            message: 'Existe uma solicitação recente!',
            description:
              'Você já solicitou uma verificação de email recentemente. Aguarde algumas horas para solicitar novamente ou entre em contato com nossa equipe de suporte.',
          });
          return;
        }

        this._appHandler.setError(true, {
          message: 'Erro ao enviar o e-mail de confirmação de conta!',
          description:
            'Tente novamente ou entre em contato com nossa equipe de suporte.',
          actionText: 'Tentar novamente',
          action: () => {
            this.resendAccountConfirmation(userId);
          },
        });
      });
  }

  showAuthMethod(config: GetConfigByCityIdOutput, strategy: string) {
    return config.methods.some((method) => method.strategy === strategy);
  }

  showAuthMethodsDivider(config: GetConfigByCityIdOutput) {
    return (
      this.showAuthMethod(config, 'CREDENTIALS') && config.methods.length > 1
    );
  }

  showSingleLoginInstructions(config: GetConfigByCityIdOutput) {
    if (config.methods.length === 0 || config.methods.length > 1) {
      return '';
    }

    const singleLoginInstructions =
      config.methods[0].config?.['singleLoginInstructions'];
    return singleLoginInstructions
      ? this._sanitizer.bypassSecurityTrustHtml(singleLoginInstructions)
      : '';
  }

  hasAlternativeAuthMethods(config: GetConfigByCityIdOutput) {
    return (
      config.methods.filter(
        (method) =>
          method.strategy !== 'CREDENTIALS' && method.strategy !== 'INTEGRATION'
      ).length > 0
    );
  }

  showGovBrMethod(config: GetConfigByCityIdOutput) {
    return config.methods.some(
      (method) => method.strategy === 'GOV_BR' && method.config?.['url']
    );
  }

  showIntegrationLoginButton(config: GetConfigByCityIdOutput) {
    return config.methods.some(
      (method) => method.strategy === 'INTEGRATION' && method.config?.['url']
    );
  }

  integrationMethodUrl(config: GetConfigByCityIdOutput) {
    return config.methods.find((method) => method.strategy === 'INTEGRATION')
      ?.config?.['url'];
  }

  loginButtonText(config: GetConfigByCityIdOutput) {
    return config.methods.find((method) => method.strategy === 'INTEGRATION')
      ?.config?.['loginButtonText'];
  }

  redirectToIntegrationUrl(config: GetConfigByCityIdOutput) {
    const integrationMethodUrl = this.integrationMethodUrl(config);
    if (integrationMethodUrl) {
      window.location = integrationMethodUrl;
    }
  }

  copyIntegrationMethodUrl(config: GetConfigByCityIdOutput) {
    const integrationMethodUrl = this.integrationMethodUrl(config);
    if (integrationMethodUrl) {
      navigator.clipboard.writeText(integrationMethodUrl).then(() => {
        this.urlCopied.set(true);

        const resetCopyToClipboardTimeout = setTimeout(() => {
          this.urlCopied.set(false);
          clearTimeout(resetCopyToClipboardTimeout);
        }, 3000);
      });
    }
  }

  getEmailFromQueryParam(): string {
    const queryParams = this._route.snapshot.queryParams;
    return queryParams['email'];
  }

  socialLogin(user: SocialUser) {
    const code = user.authToken ?? user.idToken;
    const provider = user.provider;

    this._appHandler.setLoading(true, {
      title: 'Carregando...',
      description: 'Estamos verificando as informações do Google.',
    });

    void this._router.navigate([`/${this.cityIdentity}/callback`], {
      queryParams: {
        code,
        provider,
        state: 'SOCIAL',
      },
    });
  }
}
